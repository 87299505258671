import React from 'react';
import ReactVideoJS from './ReactVideoJS';

export default class AudioPlayer extends React.Component {
    constructor(props) {
        super(props);
        
        let folderName = this.props.audioId;
        if(folderName.indexOf(".") === -1) {
            folderName = folderName + ".mp3";
        }

        this.state = {
            videoJsOptions: {
                autoplay: this.props.autoPlay || false,
                controls: true,
                preload: 'auto',
                playbackRates: [0.5, 0.7, 1, 1.2, 1.5],
                controlBar: {
                    fullscreenToggle: false,
                    pictureInPictureToggle: false,
                    skipButtons: {
                        backward: 10,
                        forward: 10,
                    }
                },
                sources: [{
                    src: 'https://play2.fra1.cdn.digitaloceanspaces.com/E2093B3D269A/' + folderName + '/playlist.m3u8',
                    type: 'application/x-mpegURL'
                    // , withCredentials: true
                }],
                html5: {
                    hls: {
                      overrideNative: true
                    },
                    nativeAudioTracks: false,
                    nativeVideoTracks: false
                },
                notSupportedMessage: "Your browser is not supported."
            }
        }
    }
    render() {
        return <ReactVideoJS {...this.state.videoJsOptions} addStartedClass={true} />
    }
}