import React, { useEffect } from 'react';
import './App.css';
import VideoPlayer from './components/VideoPlayer';
import AudioPlayer from './components/AudioPlayer';
import 'video.js/dist/video-js.css';
function App() {
  let videoId = null;
  try {
    const search = document.location.search;
    const v = search.split('v=')[1].split("&")[0];
    videoId = v;
  } catch (e) {
  }

  let audioId = null;
  try {
    const search = document.location.search;
    const a = search.split('a=')[1].split("&")[0];
    audioId = a;
  } catch (e) {
  }

  let autoPlay = false;
  try {
    const search = document.location.search;
    const a = search.split('autoPlay=')[1].split("&")[0];
    autoPlay = a === 'true' || a === '1';
  } catch (e) {
  }

  let bgHex = "FFFFFF";
  try {
    const search = document.location.search;
    const a = search.split('bgHex=')[1].split("&")[0];
    bgHex = a;
  } catch (e) {
  }

  useEffect( () => {
    // Prevent select, copy, paste, right click ( only in prod )
    [ 'selectstart', 'cut', 'copy', 'paste', 'contextmenu'].forEach ( (event) => { 
      const fn = function(e) { e.preventDefault(); return false;  };
      document.querySelector('body').addEventListener(event, fn);
      document.addEventListener(event, fn);
    }
    )
  }, []);
  

  return videoId?(
    <div className="App">
      <VideoPlayer videoId={videoId} autoPlay={autoPlay} bgHex={bgHex}/>
    </div>
  ):audioId?(
    <div className="App">
      <AudioPlayer audioId={audioId} autoPlay={autoPlay}/>
    </div>
  ):"ESSR.CH";
}

export default App;
