import React from 'react';
import ReactVideoJS from './ReactVideoJS';

const URL_PREFIX = 'https://play2.fra1.cdn.digitaloceanspaces.com/D63E4C5DE4D6/';

export default class VideoPlayer extends React.Component {
    constructor(props) {
        super(props);
        
        let folderName = this.getFolderName();

        this.bgHex = props.bgHex;

        let url = '';
        if(folderName === 'mobile-subtitle-example.webm') {
            url = "https://devstreaming-cdn.apple.com/videos/streaming/examples/adv_dv_atmos/main.m3u8";
        } else {
            url = URL_PREFIX + folderName + '/playlist.m3u8';
        }

        this.state = {
            waitingForSrtCheck: true,
            videoJsOptions: {
                autoplay: this.props.autoPlay || false,
                controls: true,
                nativeControlsForTouch: true,
                poster: 'empty.png',
                preload: 'auto',
                playbackRates: [0.5, 0.7, 1, 1.2, 1.5],
                sources: [{
                    src: url,
                    type: 'application/x-mpegURL'
                    // , withCredentials: true
                }],
                html5: {
                    hls: {
                      overrideNative: true
                    },
                    nativeAudioTracks: false,
                    nativeVideoTracks: false,
                    nativeTextTracks: false
                },
                controlBar: {
                    skipButtons: {
                        backward: 10,
                        forward: 10,
                    }
                },
                notSupportedMessage: "Your browser is not supported."
            }
        }
    }

    getFolderName() {
        let folderName = this.props.videoId;
        if(folderName.indexOf(".") === -1) {
            folderName = folderName + ".webm";
        }
        return folderName;
    }

    componentDidMount() {
        this.fetchSrtContent(this.getFolderName());
    }

    async fetchSrtContent(folderName) {
        try {
            let nameWithoutExtension = folderName.split('.')[0];
            const srtUrl = URL_PREFIX + 'srt/' + nameWithoutExtension + '.json';
            const response = await fetch(srtUrl);

            if (response.ok) {
                const srtContent = await response.json();
                console.log("SRT content: ", srtContent)
                this.setState({ 
                    srtContent,
                    videoJsOptions: {
                        ...this.state.videoJsOptions,
                        tracks: srtContent.map(sub => ({
                            srclang: sub.language,
                            label: sub.label,
                            kind: 'subtitles',
                            src: URL.createObjectURL(new Blob([this.convertSRTtoVTT(sub.content)], {type : 'text/vtt'}))
                        }))
                    }
                });
            } else {
                console.error('SRT file not found:', srtUrl);
            }

            this.setState({ waitingForSrtCheck: false});
        } catch (e) {
            console.error('Error trying to fetch and parse SRT:', e);
        }
    }

    convertSRTtoVTT(srtText) {
        const lines = srtText.split('\n');
        let buf = [];
        let result = 'WEBVTT FILE\r\n\r\n';
      
        const convert = () => {
          return buf.join('\r\n')
            .replace(/\{\\([ibu])\}/g, '</$1>')
            .replace(/\{\\([ibu])1\}/g, '<$1>')
            .replace(/\{([ibu])\}/g, '<$1>')
            .replace(/\{\/([ibu])\}/g, '</$1>')
            .replace(/(\d\d:\d\d:\d\d),(\d\d\d)/g, '$1.$2')
            .replace(/\r\n\{\\an8\}/g, ' line:5%\r\n') +
            '\r\n\r\n';
        };
      
        lines.forEach(line => {
          if (line.trim()) {
            buf.push(line.trim());
          } else {
            result += convert();
            buf = [];
          }
        });
      
        if (buf.length) {
          result += convert();
        }
      
        return result;
      }

    render() {
        if(this.state.waitingForSrtCheck) {
            return <></>;
        }
        const styleCode = `{ background-color: #${this.bgHex} !important }`;
        return <>
        <style>
            .video-js { styleCode }
        </style>
        <ReactVideoJS isVideo={true} {...this.state.videoJsOptions} addQualitySelector={true} />
        </>
    }
}