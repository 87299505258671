import React from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import videojs from "!video.js";

// The actual plugin function is exported by this module, but it is also
// attached to the `Player.prototype`; so, there is no need to assign it
// to a variable.

// eslint-disable-next-line import/no-webpack-loader-syntax
import '!videojs-contrib-quality-levels';

// The actual plugin function is exported by this module, but it is also
// attached to the `Player.prototype`; so, there is no need to assign it
// to a variable.
// eslint-disable-next-line import/no-webpack-loader-syntax
import qualitySelectorHls from '!videojs-quality-selector-hls'


export default class ReactVideoJS extends React.Component {
  componentDidMount() {
    const {addStartedClass} = this.props;
    const {addQualitySelector} = this.props;

    videojs.registerPlugin('qualitySelectorHls', qualitySelectorHls);

    // instantiate Video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {

      let videoId = null;

      window.parent.postMessage({"name": "video-playback-available", "url": document.location.href}, '*');
      
      try {
        // Store in reverse order, just to not allow easy reusing of the ID
        videoId = this.currentSource().src.split("/")[4].split("_")[1].split(".")[0].split("").reverse().join("");
      } catch (e) {};

      if(videoId) {
        const lastPosition = localStorage.getItem(`${videoId}-position`);

        // Restore previously saved position
        if(lastPosition) {
          this.currentTime(parseFloat(lastPosition));
        }
        
        // Persist position
        this.on("timeupdate", function (e) {
          localStorage.setItem(`${videoId}-position`, this.currentTime());

          // Last 30 seconds
          if(this.duration() - this.currentTime() < 30) {
            if(!window.playbackCompletionNotified) {
              window.playbackCompletionNotified = true;
              window.parent.postMessage({"name": "video-playback-completed", "url": document.location.href}, '*');
            }
          }
          
        });
      } else {
        console.error("Error parsing video ID - this can affect the tracking");
      }

      if(addStartedClass) {
        this.addClass('vjs-has-started');
      }
    });

    if(addQualitySelector) {
      // Quality selector
      this.player.qualitySelectorHls();
    }

    // Custom logo
    this.initializeCustomButton();
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    if(this.props.isVideo) {
      return (
        <div style={{width: '100vw', height: '100vh'}}>	
          <div data-vjs-player>
            <video ref={ node => this.videoNode = node } className="video-js vjs-fill vjs-show-big-play-button-on-pause vjs-big-play-centered"></video>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{width: '100vw', height: '45px'}}>	
          <div data-vjs-player>
            <audio ref={ node => this.videoNode = node } className="video-js vjs-fill"></audio>
          </div>
        </div>
      )
    }
    
  }

  initializeCustomButton() {
    // console.log("videojs.Button", videojs.getComponent('Button'));

    // const isGsds = document.location.href.indexOf("gsds.ch")!==-1;
    // let logo = "logo-essr.svg";
    // if(isGsds){
    //   logo="logo-gsds.svg";
    // }

    // var skipBehindButton = this.player.controlBar.addChild("button");
    // var skipBehindButtonDom = skipBehindButton.el();
    // skipBehindButtonDom.style="width: 100px;";
    // skipBehindButtonDom.innerHTML = `<a href="https://essr.ch" target="_blank"><img src="/${logo}" width="50" height="20"/></a>`;
    // skipBehindButton.addClass("buttonClass");

  }
}
